import angularModule from 'ItemGroupsEditor/angularModule/scripts/item_groups_editor_module';
import template from 'ItemGroupsEditor/angularModule/views/item_groups_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('itemGroupsEditor', [
    '$injector',

    function factory($injector) {
        const Group = $injector.get('Group');
        const $timeout = $injector.get('$timeout');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                item: '<',
                readonly: '<?',
            },
            link(scope, elem) {
                scope.EDITOR_FORM_CLASSES = $injector.get('EDITOR_FORM_CLASSES');
                scope.proxy = {};

                Group.cachedIndex().then(groups => {
                    scope.groups = groups;
                });

                let groupsById;
                scope.$watchCollection('groups', groups => {
                    if (!groups) {
                        return;
                    }
                    groupsById = _.keyBy(groups, 'id');

                    $timeout(() => {
                        const select = elem.find('select')[0];
                        if (select) {
                            select.selectize.refreshOptions();
                        }
                    });
                });

                scope.$watchCollection('item.groups', groups => {
                    scope.proxy.groupIds = _.map(groups, 'id');
                });

                scope.$watchCollection('proxy.groupIds', (newVal, oldVal) => {
                    if (!oldVal || angular.equals(newVal, oldVal) || !groupsById) {
                        return;
                    }

                    scope.item.groups = _.map(newVal, id => {
                        if (!groupsById[id]) {
                            throw new Error(`No group found for "${id}".`);
                        }
                        return _.pick(groupsById[id], 'id', 'name'); // avoid unsaved changes warnings
                    });
                });
            },
        };
    },
]);
