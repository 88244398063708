import 'angular-selectize2/dist/angular-selectize';

import 'Groups/angularModule';
import 'AdminEditorReportsShared/angularModule';

export default angular.module('ItemGroupsEditor', [
    'FrontRoyal.Groups',
    'FrontRoyal.AdminEditorReportsShared',
    'selectize',
]);
