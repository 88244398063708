export default angular.module('jQueryHelper', []).factory('jQueryHelper', [
    () => ({
        // Use this function to setup element watchers with `on` that
        // will be destroyed when the scope is destroyed.
        onUntilDestroyed(scope, elem, evt, callback) {
            if (!scope._namespacedJQueryEventWatchers) {
                scope._namespacedJQueryEventWatchers = [];
                scope.$on('$destroy', () => {
                    _.forEach(scope._namespacedJQueryEventWatchers, entry => {
                        entry.elem.off(entry.namespacedEvent);
                    });
                });
            }

            const namespacedEvent = `${evt}.namespacedJQueryEventWatchers-${scope.$id}`;
            scope._namespacedJQueryEventWatchers.push({
                elem,
                namespacedEvent,
            });
            elem.off(namespacedEvent);
            elem.on(namespacedEvent, callback);
            return elem;
        },
    }),
]);
